/* You can add global styles to this file, and also import other style files */
@import '~ng-pick-datetime-ex/assets/style/picker.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
@import '@angular/cdk/overlay-prebuilt.css';
@import '~normalize.css/normalize.css';
@import 'styles/vars';
@import './styles/fontello';
@import './styles/fonts';

html {
  margin: 0;
  padding: 0;
  width: 100vw;
}
body {
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: #d8d8d8;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(60, 71, 75, 0.8);
  }
  @include tablet {
    overflow-y: initial;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input::-webkit-input-placeholder {
  color: #b3b3b3;
}

input::-moz-placeholder {
  color: #b3b3b3;
}

input:-ms-input-placeholder {
  color: #b3b3b3;
}

input:-moz-placeholder {
  color: #b3b3b3;
}

//body button
//  border-radius: 2px;

//button
//  border: none;
//  outline: none;
//  font-family: 'Roboto', Arial, sans-serif;
//  cursor: pointer;
//  min-width: 70px;
//  font-size: 14px;
//  line-height: 16px;
//  font-weight: 500;
//  padding: 7px 20px;
//  background-color: #ebeded;
//  color: #3c474b;
//  box-sizing: border-box;
//  transition: opacity 0.3s ease, color 0.3s ease, background-color 0.3s ease;
//
//button:first-letter
//  text-transform: uppercase;
//
//button.primary
//  background-color: #f32052;
//  color: #ffffff;
//
//button:hover
//  background-color: #c60a37;
//  color: white;
//
//button.blue
//  background-color: #dcf6ff;
//  color: #14bffb;
//
//button.blue:hover
//  background-color: #14bffb;
//  color: white;
//
//button.snackbar
//  background-color: #ffddcc;
//  color: #ff5300;
//
//button.snackbar:hover
//  background-color: #c60a37;
//  color: #ffffff;

// Styles for modals

.modal_background {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(60, 71, 75, 0.2);
  font-family: 'proxima-nova-regular', 'Roboto', 'Arial', sans-serif;
}

.modal_container {
  background: white;
  width: 100%;
  max-width: 660px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);

  header {
    padding: 15px 20px;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: solid 1px rgba(173, 178, 187, 0.5);

    h3 {
      margin: 0;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }

    .close {
      margin-left: auto;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .body {
    .row {
      padding: 20px;

      h4 {
        margin: 0;
        line-height: 19px;
        font-size: 16px;
        font-weight: 500;
        color: #14bffb;
      }
    }
  }

  footer {
    display: flex;
    width: 100%;
    padding: 15px 0;
    justify-content: flex-end;

    button {
      margin-right: 20px;
      border-radius: 2px;
    }
  }
}

.confirmation-delete-dialog {
  .confirm-btn {
    button {
      background: $error-color !important;
    }
  }
}
//  Array items with possibility to exclude them form array

.removable_items_container {
  display: flex;
  flex-wrap: wrap;

  .removable_items_container__item {
    border-radius: 4px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 7px 10px;
    cursor: pointer;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      line-height: 26px;
      vertical-align: middle;
      margin: 0 10px;
      font-size: 14px;
    }

    span {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      color: #fff;
      background-color: #c4c4c4;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.2s linear;
    }

    span:hover {
      background-color: #b0b0b0;
    }
  }

  .removable_items_container__item:hover {
    span {
      opacity: 1;
    }
  }
}
.spinner_container {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(60, 71, 75, 0.2);
}
// Multiselect with single selection option
.selector_row.single_selection .cuppa-dropdown {
  ::ng-deep .c-btn {
    > span:nth-of-type(1) {
      display: block;
      color: #14bffb;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
    }

    > span:nth-of-type(1) {
      > span {
        color: black;
        display: inline;
      }
    }

    ::ng-deep span.c-angle-down,
    ::ng-deep span.c-angle-up {
      width: 0;
      height: 0;
      right: 0;
      top: 0;
      position: static;
      transform: none;

      > svg {
        display: none;
      }
    }

    span:before {
      content: none;
    }
  }

  ::ng-deep .selected-list .c-btn:after {
    content: '';
  }
}
input.ng-invalid.ng-touched + .selector_row.single_selection .cuppa-dropdown {
  ::ng-deep .c-btn {
    border: solid 0.5px #ff5300 !important;

    > span:nth-of-type(1) {
      color: #ff5300;
    }

    > span:last-of-type:before {
      content: url('./assets/img/arrow_orange.svg');
    }
  }
}
//Component page common styles
.page_container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 74px);
  max-height: calc(100vh - 74px);
  max-width: 80vw;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  font-family: 'proxima-nova-regular', 'Roboto', 'Arial', sans-serif;
}
.component_window {
  background-color: #fff;
  margin: 0px 40px 60px;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

  > .tabs {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px 0px;
    display: flex;
    flex-wrap: nowrap;

    .tab_item {
      position: relative;
      padding: 15px 20px;
      cursor: pointer;
      text-align: center;
      min-width: 210px;
      font-weight: 500;
      color: #3c474b;
      transition: color 0.4s ease;

      span {
        color: #3c474b;
        font-weight: 400;
      }
    }

    .tab_item:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      transform-origin: 50% 50%;
      transform: scaleX(0);
      transition: transform 0.4s ease;
      height: 2px;
      bottom: 0px;
      left: 0;
      background: #f32052;
    }

    .tab_item.active {
      color: #f32052;
    }

    .tab_item.active:after {
      transform: scaleX(1);
    }
  }
}
//  Tables common styles

.table_cell:last-of-type {
  border-right: 0.5px solid transparent;
}

.chat_area_wrapper {
  max-width: 940px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 auto;
}
.table_row_wrapper:hover {
  .table_cell {
    border-color: rgba(0, 0, 0, 0);
  }

  .table_row:hover {
    box-shadow: none;
  }
}
.table_header,
.table_row {
  display: grid;
  width: 100%;
  position: relative;
}
.table_row {
  font-size: 14px;
}
.table_header {
  border-top: 1px solid rgba(173, 178, 187, 0.5);
  border-bottom: 1px solid rgba(173, 178, 187, 0.5);
  height: 50px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: #c5c8c9;
}
.header_cell {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid transparent;
}
.table_cell {
  display: flex;
  height: 60px;
  line-height: 16px;
  color: black;
  box-sizing: border-box;
  border-right: 1px solid rgba(173, 178, 187, 0.5);
  transition: border-right-color 0.5s ease;
  align-items: center;
}

// Popup grid cells
.grid_cell.two_columns {
  grid-template-columns: 1fr 1fr;
}
.grid_cell {
  display: grid;
  grid-gap: 10px 20px;

  .cell {
    border-radius: 4px;
    overflow: hidden;
    height: 50px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    background: #f7f7f7;
    color: #3c474b;
    width: 100%;
    padding: 0 20px;
    flex-shrink: 0;
  }

  .blue.cell {
    background: #f3fcff;
  }

  .cell.bold {
    font-weight: bold;
  }

  .cell.amaranth {
    color: #f32052;
  }

  .cell.extreme {
    background-color: #fceee7;
    color: #3c474b;
  }

  .cell.right {
    justify-content: flex-end;
  }
}
// Expand button
.expand_icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d6d8dd;
  border-radius: 50%;
  flex-shrink: 0;
  transform-origin: center center;
  transition: transform 0.5s ease-in-out, background-color 0.3s ease;
}

.expand_icon.expanded {
  transform: rotate(-180deg);
}

.component_window {
  header {
    border-bottom: solid 0.5px rgba(173, 178, 187, 0.5);
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;

    h4 {
      line-height: 15px;
      font-size: 12px;
      font-weight: 500;
      padding-left: 20px;
      color: #c5c8c9;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .body {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
}
.cdk-overlay-container {
  z-index: 999999;
}

.cdk-overlay-dark-backdrop {
  background: $black-deep_50;
}

mat-dialog-container.mat-dialog-container {
  padding: 0 24px;
  height: auto;
  border-radius: 8px;

  @include mobile {
    padding: 0 16px;
  }
}
::ng-deep.mat-option-text {
  text-transform: capitalize;
}
.no-scroll {
  overflow: hidden !important;
}

.owl-dt-popup.date-picker-popup {
  @include mobile-small {
    left: 8px !important;
  }
}
// Charts
.app-chart {
  text {
    font-family: $font-family;
    color: $blue-gray-text;
    fill: $blue-gray-text;
    font-size: 12px;
  }

  .ngx-charts {
    float: none;
  }

  g.line-chart > g:last-of-type > g g.line-series > path {
    stroke-width: 4;
    stroke-linecap: round;
  }

  &.no-horizontal-grid .gridline-path-horizontal {
    display: none;
  }

  &.no-vertical-grid .gridline-path-vertical {
    display: none;
  }

  &.no-y-ticks .y .tick {
    display: none;
  }

  [ngx-charts-axis-label] {
    text {
      font-family: $font-family-bold;
    }
  }

  .ngx-charts-outer {
    display: flex;
    flex-direction: column-reverse;
  }

  .chart-legend {
    .legend-title {
      display: none;
    }

    .legend-labels {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;

      .legend-label {
        font-family: $font-family;
        color: $blue-gray-text;
        font-size: 12px;
        font-weight: 600;

        &-color {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          margin-top: 3px;
        }

        &-text {
          width: calc(100% - 10px);
        }
      }


    }
  }
}

.trial-period-container {
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
    border-radius: 0;

    @include tablet {
      margin: 24px;
    }

    @include mobile {
      margin: 16px;
    }

    @include mobile-small {
      margin: auto 16px;
      height: fit-content;
    }
  }
}

.pai-score-header {
  @include mobile {
    .header {
      display: flex;
      flex-direction: column;

      &-content {
        width: 100%;

        @include tablet {
          margin-top: 16px;
        }
      }
    };
  }
}
