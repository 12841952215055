@mixin laptop {
  @media screen and (max-width: 1440px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 1279px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin mobile-small {
  @media screen and (max-width: 500px) {
    @content;
  }
}
@mixin button($bg-color, $color, $bg-color-hover, $color-hover) {
  padding: 8px 24px;
  font-size: 0.875rem;
  border: none;
  outline: none;
  background: $bg-color;
  color: $color;
  cursor: pointer;
  border-radius: 2px;
  font-family: $font-family;
  transition: 150ms ease-out;
  display: flex;
  justify-content: center;
  &:hover {
    background: $bg-color-hover;
    color: $color-hover;
    i {
      path {
        fill: $color-hover;
      }
    }
  }
  &:disabled,
  &.disabled {
    background: none;
    opacity: 0.7;
    cursor: default;
    pointer-events: none;
    color: $black;
  }
  i {
    margin-right: 8px;
    path {
      transition: 150ms ease-out;
      fill: $color;
    }
  }
}
@mixin icon-button($bg: none, $bg-hover: $black_10, $fill: $dark-grey-blue, $fill-hover: $dark-grey-blue) {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: $bg;
  padding: 0;
  min-width: 32px;
  min-height: 32px;
  transition: 250ms ease-out;
  cursor: pointer;
  border: none;
  outline: none;
  &:hover,
  &.active {
    background: $bg-hover;
    path {
      fill: $fill-hover;
    }
  }
  svg {
    width: 16px;
  }
  path {
    transition: 250ms ease-out;
    fill: $fill;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    fill: $black_10;
  }
}
@mixin round-button($bg-color, $color, $bg-color-hover, $color-hover) {
  background-color: $bg-color;
  color: $color;
  cursor: pointer;
  transition: 150ms ease-out;
  text-transform: uppercase;
  height: 48px;
  box-sizing: border-box;
  padding: 0 24px 0 16px;
  font-weight: 300;
  border-radius: 48px;
  user-select: none;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  &--no-icon {
    padding: 0 16px;
  }
  svg {
    margin-right: 8px;
  }
  path {
    fill: $color;
    transition: fill 150ms ease-out;
  }
  &:hover {
    background-color: $bg-color-hover;
    color: $color-hover;
    path {
      fill: $color-hover;
    }
  }
  &:disabled,
  &--disbled {
    background-color: $black_10;
    pointer-events: none;
  }
}
@mixin rounded-button($bg-color, $color, $bg-color-hover, $color-hover, $br-color: $bg-color) {
  background-color: $bg-color;
  color: $color;
  cursor: pointer;
  transition: 150ms ease-out;
  height: 40px;
  box-sizing: border-box;
  padding: 0 24px 0 16px;
  font-weight: 300;
  border-radius: 8px;
  user-select: none;
  display: flex;
  align-items: center;
  outline: none;
  border: 1px solid $br-color;
  &--no-icon {
    padding: 0 16px;
  }
  svg {
    margin-right: 8px;
  }
  path {
    fill: $color;
    transition: fill 150ms ease-out;
  }
  &:hover {
    background-color: $bg-color-hover;
    color: $color-hover;
    path {
      fill: $color-hover;
    }
  }
  &:disabled,
  &--disbled {
    background-color: $light-gray-border;
    pointer-events: none;
    border: 1px solid $light-gray-border;
  }
}

@mixin share-link-button {
  &:active {
    path {
      fill: $white;
    }

    ::ng-deep {
      button {
        background: $primary;
      }
    }
  }
}

@mixin page {
  display: grid;
  padding: 20px 32px 32px;
  background-color: $white;
  box-sizing: border-box;
  font-family: $font-family;
  max-width: 100%;
  grid-template-rows: 0;
  overflow: auto;
  height: 100vh;
  grid-template-columns: 0 minmax(500px, 1440px) 1fr;
  &--activity-opened {
    @include tablet {
      height: 0;
    }
  }
  @include tablet {
    grid-template-rows: auto;
    padding: 16px 16px 0;
    height: calc(100vh - 72px);
    overflow: initial;
    width: 100vw;
  }
  .page_header {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: nowrap;
    height: 30px;
    justify-content: flex-end;
    align-items: center;
    grid-column: 1/4;
  }
  .page_card {
    background-color: $white;
    //box-shadow: 0 4px 10px 0 $black_10
    display: flex;
    flex-direction: column;
    margin: 0;
    height: 100%;
    grid-row: 2/3;
    grid-column: 2/2;
    border-radius: 8px;
    @include tablet {
      grid-row: 1/5;
      border-radius: 0;
      box-shadow: none;
    }
    @include mobile {
      width: calc(100vw - 32px);
    }
    // &--activity
    //   @include tablet
    //     position: fixed
    //     top: 0
    //     z-index: 9999
    //     height: 100vh
    //     overflow: auto
    header.card_header {
      display: flex;
      align-items: center;
      height: 48px;
      min-height: 48px;
      line-height: 15px;
      font-size: 0.75rem;
      font-weight: 500;
      color: $black_30;
      margin-bottom: 16px;
      text-transform: uppercase;
      width: 100%;
      box-sizing: border-box;
      z-index: 10;
      justify-content: space-between;
      position: sticky;
      background: $white;
      top: -20px;
      padding: 0px 30px;
      border-radius: 48px;
      background-color: $white;
      box-shadow: 0px 2px 4px $grey_20, 0px 4px 5px $grey_20, 0px 1px 10px $grey_20;
      overflow-y: hidden;
      &.mobile-header {
        position: fixed;
        top: 88px;
        z-index: 99;
      }
      &.static_card_header {
        overflow-y: visible;
        @include mobile {
          width: 100%;
        }
      }
      @include mobile {
        border-radius: 0;
        width: calc(100% + 32px);
      }
    }
  }
  .page_content {
    height: calc(100% - 64px);
    @include tablet {
      height: calc(100% - 48px);
    }
  }
}
@mixin tabs {
  display: flex;
  height: 100%;
  position: relative;
  left: 0;
  right: 0;
  max-width: 100vw;
  overflow: hidden;
  margin: 0 40px;
  position: absolute;
  top: 0;
}
@mixin tab {
  cursor: pointer;
  color: $blue-gray-text;
  min-width: 192px;
  padding: 16px 24px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  white-space: nowrap;
  flex-shrink: 0;
  @include tablet {
    height: 48px;
  }
  &:after {
    width: 0;
    content: '';
    transition: 250ms ease-out;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: $primary;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  &--active {
    color: $primary;
    font-weight: 500;
    &:after {
      width: 100%;
    }
  }
  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
@mixin input {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    font-size: 16px;
    color: $blue;
    text-transform: capitalize;
    margin-bottom: 5px;
    transition: 150ms ease-out;
  }
  input,
  div.disabled,
  div.input_dummy {
    padding: 10px 15px;
    border-radius: 2px;
    border: 0.5px solid $black_10;
    outline: none;
    transition: 150ms ease-out;
    &:focus {
      border: 0.5px solid $blue;
    }
    &.disabled,
    &:disabled {
      cursor: not-allowed;
      color: $black_30;
    }
  }
  &.error {
    label,
    input {
      color: $orange;
    }
    input {
      border: solid 0.5px $orange;
    }
  }
  .errors {
    color: $orange;
    font-size: 0.875rem;
    margin-top: 8px;
  }
  ::ng-deep mat-form-field {
    .mat-form-field-infix {
      border: 0.5px solid $black_10;
      border-radius: 2px;
      padding: 10px 15px;
    }
    .mat-form-field-underline {
      display: none;
    }
    mat-select-trigger {
      font-weight: 500;
      font-size: 1rem;
      color: $blue;
      text-transform: capitalize;
      position: absolute;
    }
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
}
@mixin modal {
  font-family: $font-family;
  display: grid;
  grid-template-rows: 48px auto 48px;
  &--no-action {
    grid-template-rows: 48px auto;
  }
  &-header {
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-bottom: solid 0.5px $black_10;
    text-transform: capitalize;
    &-close {
      @include icon-button;
      margin-left: auto;
      svg {
        width: 24px;
      }
    }
  }
  &-body,
  &-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-primary {
      @include button($primary, $white, $primary-dark, $white);
      text-transform: capitalize;
    }
    &-secondary {
      @include button($black_10, $black, $primary-dark, $white);
      text-transform: capitalize;
      margin-right: 8px;
    }
  }
}
@mixin clickable-element {
  cursor: pointer;
  user-select: none;
  // hide all highlight by tap
  -webkit-tap-highlight-color: transparent;
  // hide all outlines by tap and click
  outline: none;
}
@function rem ($px) {
  @return ($px/16)+rem;
}

@mixin font-regular {
  font-family: proxima-nova-regular, sans-serif;
  font-weight: 400;
}
@mixin font-semibold {
  font-family: proxima-nova-semibold, sans-serif;
  font-weight: 600;
}
@mixin font-bold {
  font-family: proxima-nova-bold, sans-serif;
  font-weight: 700;
}
@mixin elevation {
  box-shadow: 0px 2px 4px rgba(157, 165, 185, 0.14), 0px 4px 5px rgba(157, 165, 185, 0.12), 0px 1px 10px rgba(157, 165, 185, 0.2);
}
@mixin elevation-large {
  box-shadow: 0px 16px 24px rgba(86, 92, 107, 0.14), 0px 6px 30px rgba(86, 92, 107, 0.12), 0px 8px 10px rgba(86, 92, 107, 0.2);
}
