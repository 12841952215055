@import 'mixins';

// should also be in guidelines in figma
$white: rgba(255, 255, 255, 1);
$white_30: rgba(255, 255, 255, 0.3);
$black-deep: rgba(0, 0, 0, 1);
$black-deep_70: rgba(0, 0, 0, 0.7);
$black-deep_50: rgba(0, 0, 0, 0.5);
$black: rgba(60, 71, 75, 1);
$black_80: rgba(60, 71, 75, 0.8);
$black_30: rgba(60, 71, 75, 0.3);
$black_10: rgba(60, 71, 75, 0.1);
$black_05: rgba(60, 71, 75, 0.05);
$black_5: rgba(60, 71, 75, 0.05);

$surface: #ffffff;

$error-color: #E8430E;

$grey: rgba(121, 128, 131, 1);
$light-grey: rgba(216, 216, 216, 1);
$grey_20: rgba(157, 165, 185, 0.2);
$dark-grey-blue: rgba(96, 104, 115, 1);

$primary: #5C3CCD;
$primary-dark: #4123A9;
$primary-light: #DFD6FF;

$blue: rgba(20, 191, 251, 1);
$blue-deep: rgba(10, 181, 241, 1);
$blue_15: rgba(20, 191, 251, 0.15);
$blue_20: rgba(20, 191, 251, 0.2);
$blue_30: rgba(20, 191, 251, 0.3);
$blue_50: rgba(20, 191, 251, 0.5);
$blue_5: rgba(20, 191, 251, 0.05);

$blue-gray: #F4F6FA;
$blue-gray-text: #606873;
$blue-deep-lighter: #DCF6FF;
$accent-blue: #14BFFB;
$accent-blue-darker: #0ab5f1;
$table-text-color: #62646B;
$table-header-color: #9899A0;
$table-active-color: #00BD8F;
$table-inactive-color: #F32052;

$violet: #6C42DD;

$black-icon: #2E2E40;

$orange: rgba(255, 83, 0, 1);
$orange_10: rgba(255, 83, 0, 0.1);

$light-green: rgba(0, 226, 91, 1);
$green: #3BCC99;

$disabled_btn: rgba(235, 237, 237, 0.95);
$disabled_nav_btn: #f5f5f5;

$search-field-bg: rgba(247, 247, 247, 0.84);
$close-btn: rgba(196, 196, 196, 1);
$action-btn-grey: rgba(235, 237, 237, 1);
$load-image-btn: rgba(216, 216, 216, 1);
$disabled-btn-color: #79798A;
$disabled-btn-border: #D4D4DB;
$disabled-btn-background: #F2F2F2;

$send-btn: rgba(20, 191, 251, 1);
$send-btn-hover: rgba(0, 158, 214, 1);
$add-image-btn: rgba(190, 190, 190, 0.85);

$light-gray-border: #E8ECF3;
$gray-divider: #EEEFF0;

$font-family: 'proxima-nova-regular', 'Roboto', Arial, sans-serif;
$font-family-bold: 'proxima-nova-bold', 'Roboto', Arial, sans-serif;

$material-button-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
$card-shadow: 0px 2px 4px rgba(157, 165, 185, 0.14), 0px 4px 5px rgba(157, 165, 185, 0.12), 0px 1px 10px rgba(157, 165, 185, 0.2);
$yellow: #FFC700;

$chart-line-green: #0E9063;
$chart-line-light-green: #3BCC99;
$chart-line-purple: #6C42DD;
$chart-line-blue: #14BFFB;
$chart-line-red: #F11C48;

$unread-chat-message: #F3F0FF;
$hovered-chat-item: #ECE6FF;
