@font-face {
  font-family: 'memento-icons';
  src: url('../assets/fonts/fontello/memento-icons.eot?76512156');
  src: url('../assets/fonts/fontello/memento-icons.eot?76512156#iefix') format('embedded-opentype'),
  url('../assets/fonts/fontello/memento-icons.woff2?76512156') format('woff2'),
  url('../assets/fonts/fontello/memento-icons.woff?76512156') format('woff'),
  url('../assets/fonts/fontello/memento-icons.ttf?76512156') format('truetype'),
  url('../assets/fonts/fontello/memento-icons.svg?76512156#memento-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-memento"],
[class*=" icon-memento"] {
  &:before {
    font-family: "memento-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // modificators
  &.icon-bold:before {
    font-weight: bold;
  }
}

.icon-memento-plus:before { content: '\e800'; } /* '' */
.icon-memento-bucket:before { content: '\e801'; } /* '' */
.icon-memento-info-icon:before { content: '\e802'; } /* '' */
.icon-memento-ckeck-icon:before { content: '\e803'; } /* '' */
.icon-memento-ic_step:before { content: '\e804'; } /* '' */
.icon-memento-close-icon:before { content: '\e805'; } /* '' */
.icon-memento-link:before { content: '\e806'; } /* '' */
.icon-memento-message_send:before { content: '\e807'; } /* '' */
.icon-memento-close-icon-bold:before { content: '\e808'; } /* '' */
.icon-memento-search-icon:before { content: '\e809'; } /* '' */
.icon-memento-arrow-left-icon:before { content: '\e80a'; } /* '' */
.icon-memento-arrow-right:before { content: '\e80b'; } /* '' */
.icon-memento-help-guide:before { content: '\e80c'; } /* '' */
.icon-memento-download:before { content: '\e80d'; } /* '' */
.icon-memento-help:before { content: '\e80e'; } /* '' */
