@font-face {
  font-family: 'proxima-nova-regular';
  src: url('../assets/fonts/proximanova/proxima_nova_regular.eot?76512156');
  src: url('../assets/fonts/proximanova/proxima_nova_regular.eot?76512156#iefix') format('embedded-opentype'),
  url('../assets/fonts/proximanova/proxima_nova_regular.woff2?76512156') format('woff2'),
  url('../assets/fonts/proximanova/proxima_nova_regular.woff?76512156') format('woff'),
  url('../assets/fonts/proximanova/proxima_nova_regular.ttf?76512156') format('truetype'),
  url('../assets/fonts/proximanova/proxima_nova_regular.svg?76512156#proxima-nova-regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima-nova-semibold';
  src: url('../assets/fonts/proximanova/proxima_nova_regular.eot?76512156');
  src: url('../assets/fonts/proximanova/proxima_nova_regular.eot?76512156#iefix') format('embedded-opentype'),
  url('../assets/fonts/proximanova/proxima_nova_semibold.woff2?76512156') format('woff2'),
  url('../assets/fonts/proximanova/proxima_nova_semibold.woff?76512156') format('woff'),
  url('../assets/fonts/proximanova/proxima_nova_semibold.ttf?76512156') format('truetype'),
  url('../assets/fonts/proximanova/proxima_nova_semibold.svg?76512156#proxima-nova-semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima-nova-bold';
  src: url('../assets/fonts/proximanova/proxima_nova_regular.eot?76512156');
  src: url('../assets/fonts/proximanova/proxima_nova_regular.eot?76512156#iefix') format('embedded-opentype'),
  url('../assets/fonts/proximanova/proxima_nova_bold.woff2?76512156') format('woff2'),
  url('../assets/fonts/proximanova/proxima_nova_bold.woff?76512156') format('woff'),
  url('../assets/fonts/proximanova/proxima_nova_bold.ttf?76512156') format('truetype'),
  url('../assets/fonts/proximanova/proxima_nova_bold.svg?76512156#proxima-nova-bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
